<script setup lang="ts">
import SVGLeft from '@mop/shared/images/arrow/left.svg?component';
import SVGRight from '@mop/shared/images/arrow/right.svg?component';
import { scrollToPosition } from '@mop/shared/utils/util';

defineOptions({
  name: 'UiPagination',
});

const props = defineProps({
  page: {
    type: String,
    default: '',
  },
  totalPages: {
    type: Number,
    default: 1,
  },
  totalItemsText: {
    type: String,
    default: '',
  },
  getLocalePathByPage: {
    type: Function,
    required: true,
  },
});

const UI_PAGINATION_ELLIPSIS = '...';
const FIRST_PAGE = 1;
const EDGE_LEFT = 3;
const EDGE_RIGHT = 2;
const DIFF_TO_SHOW_ELLIPSIS = 2;
const currentPage = props.page ? parseInt(props.page) : FIRST_PAGE;
const isFirstPageRef = currentPage === FIRST_PAGE;
const isLastPageRef = computed(() => currentPage === props.totalPages);

function fillArray(list: Array<number | string>, start: number, end: number) {
  for (let i = start; i <= end; i++) {
    list.push(i);
  }
}

const pages = computed(() => {
  const lastPage = props.totalPages;
  const pages: Array<number | string> = [];
  const edgeRightPage = props.totalPages - EDGE_RIGHT;

  if (lastPage <= 8) {
    fillArray(pages, FIRST_PAGE, lastPage);
    return pages;
  }

  if (currentPage <= EDGE_LEFT || currentPage >= edgeRightPage) {
    const leftEnd = currentPage === EDGE_LEFT ? EDGE_LEFT + 1 : EDGE_LEFT;
    const rightStart = currentPage === edgeRightPage ? edgeRightPage - 1 : edgeRightPage;
    fillArray(pages, FIRST_PAGE, leftEnd);
    if (rightStart - leftEnd > DIFF_TO_SHOW_ELLIPSIS) {
      pages.push(UI_PAGINATION_ELLIPSIS);
    } else {
      fillArray(pages, leftEnd + 1, rightStart - 1);
    }
    fillArray(pages, rightStart, lastPage);
    return pages;
  }

  const fromPage = currentPage - 1;
  const toPage = currentPage + 1;

  if (fromPage - DIFF_TO_SHOW_ELLIPSIS <= FIRST_PAGE) {
    fillArray(pages, FIRST_PAGE, fromPage - 1);
  } else {
    pages.push(FIRST_PAGE, UI_PAGINATION_ELLIPSIS);
  }

  fillArray(pages, fromPage, toPage);

  if (lastPage - toPage <= DIFF_TO_SHOW_ELLIPSIS) {
    fillArray(pages, toPage + 1, lastPage);
  } else {
    pages.push(UI_PAGINATION_ELLIPSIS, lastPage);
  }

  return pages;
});

function handlePaginationClick() {
  scrollToPosition(0, false);
}
</script>

<template>
  <div class="ui-mop-pagination-wrapper">
    <div v-if="totalPages > 1" class="ui-mop-pagination" data-cy="pagination-links">
      <NuxtLink
        v-if="!isFirstPageRef"
        class="ui-mop-pagination__arrow"
        :to="getLocalePathByPage(currentPage - 1)"
        data-cy="pagination-link-page-arrow-previous"
        no-prefetch
        @click="handlePaginationClick"
      >
        <SVGLeft width="16" height="16" />
      </NuxtLink>

      <ul class="ui-mop-pagination__page-list">
        <li v-for="(pageNumber, index) in pages" :key="`page-${index}`">
          <NuxtLink
            v-if="pageNumber !== UI_PAGINATION_ELLIPSIS"
            :class="[
              'ui-mop-pagination__page-link',
              {
                'ui-mop-pagination__page-link--current': pageNumber === currentPage,
              },
            ]"
            :to="getLocalePathByPage(pageNumber)"
            :data-cy="pageNumber === currentPage ? 'pagination-current' : null"
            :data-cy-pagination-index="`${index + 1}`"
            no-prefetch
            @click="handlePaginationClick"
          >
            <span class="ui-mop-pagination__page-link-text">
              {{ pageNumber }}
            </span>
          </NuxtLink>
          <span v-else class="ui-mop-pagination__spacer">
            {{ pageNumber }}
          </span>
        </li>
      </ul>

      <NuxtLink
        v-if="!isLastPageRef"
        class="ui-mop-pagination__arrow"
        :to="getLocalePathByPage(currentPage + 1)"
        data-cy="pagination-link-page-arrow-next"
        no-prefetch
        @click="handlePaginationClick"
      >
        <SVGRight width="16" height="16" />
      </NuxtLink>
    </div>
    <div v-if="totalItemsText" class="ui-mop-pagination__count">
      {{ totalItemsText }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ui-mop-pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include apply-upto(small) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
}

.ui-mop-pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ui-mop-pagination__page-list {
  display: flex;
}

.ui-mop-pagination__spacer {
  @include text-style(strong);

  display: inline-flex;
  padding: $space10;
  opacity: $disabled-opacity;
}

.ui-mop-pagination__page-link {
  @include link-not-underlined($space10, strong);
  @include text-style(strong);

  padding-left: $space10;
  padding-right: $space10;
  opacity: $disabled-opacity;

  &::after {
    left: $space10;
    right: $space10;
    width: auto;
  }
}

.ui-mop-pagination__page-link--current {
  opacity: 1;
}

.ui-mop-pagination__arrow {
  padding: $space10;
  display: flex;
  margin-bottom: $space2;
}

.ui-mop-pagination__count {
  @include text-style(strong);

  padding: 0 $global-padding;
  text-transform: uppercase;

  @include apply-from(medium) {
    margin-left: auto;
  }
}
</style>
